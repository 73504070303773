import { Component, Input, Output, EventEmitter, ContentChild, TemplateRef, OnChanges, HostBinding } from '@angular/core';
import { Application } from 'projects/common/src/lib/models/applications/application';
import { S3Service } from '../../services/api/s3.service';
import { S3DocumentSearch } from '../../models/s3/s3-document-search';
import { NotificationService } from 'projects/common/src/lib/services/notification.service';
const noItem = {};
const noItemRow = [noItem];

@Component({
  selector: 'app-application-list',
  templateUrl: './application-list.component.html',
  styleUrls: ['./application-list.component.scss'],
})
export class ApplicationListComponent implements OnChanges {
  @Input() list: Application[];
  @Input() hideOnEmpty: boolean = false;
  @HostBinding('class.d-none') hide: boolean = false;
  isLoading: boolean;
  datasource: (Application | object)[];
  noItemsColumns = ['noItems'];
  @Input() title: string;
  @Input() reviewLatest: boolean;
  @Input() columns = ['orderNumber', 'residency', 'applicationDate', 'paymentDate', 'approvedOn', 'status'];
  //optional template to use for the application.status column
  @ContentChild(TemplateRef) statusTemplate: TemplateRef<any>;

  constructor(
    private s3Service: S3Service,
    protected notificationService: NotificationService
  ) {}

  isEmpty = (_, application) => (application === noItem ? true : false);

  async ngOnChanges(): Promise<any> {
    if (this.list?.length) {
      this.hide = false;
      if (this.datasource != this.list) {
        this.datasource = this.list;
        await Promise.all(this.list.map(app => this.linkS3Refs(app))); //get S3 links for new doc list
      }
    } else {
      this.hide = this.hideOnEmpty;
      this.datasource = noItemRow;
    }
  }

  linkS3Refs = (app: Application): Promise<any> =>
    app.entityId
      ? this.s3Service
          .getLatestRef(
            new S3DocumentSearch({
              aggregateRootId: app.entityId,
              subjectId: app.id,
              documentType: 'Application',
            })
          )
          .then(ref => {
            app.snapshotDocumentRef = ref;
          })
      : Promise.resolve();

  openS3Link(application: Application) {
    this.s3Service.open(application.snapshotDocumentRef);
    return false;
  }
}
