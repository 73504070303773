import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ArchitectLicense } from 'projects/common/src/lib/models/architect-license';
import { ArchitectApplicationService } from 'projects/common/src/lib/services/api/architect-application.service';
import { AuthService } from 'projects/common/src/lib/services/auth/auth.service';
import { Address } from 'projects/common/src/lib/models/address';
import { LinkInfo } from 'projects/common/src/lib/models/links/link-info';
import { Transaction } from 'projects/common/src/lib/models/transaction';
import { ArchitectApplication } from 'projects/common/src/lib/models/applications/architect-application';
import { ArchitectAvailableApplication } from 'projects/common/src/lib/models/applications/architect-available-application';
import { PersonPointOfContact } from 'projects/common/src/lib/models/person-point-of-contact';
import { PointOfContactFor } from 'projects/common/src/lib/models/point-of-contact-for';
import { Mutex } from 'projects/common/src/lib/utility/mutex';
import { JurisdictionConstants } from 'projects/common/src/lib/constants/jurisdiction/jurisdiction-constants';
import { PersonFull } from 'projects/common/src/lib/models/person-full';
import {
  PersonDetailBaseComponent,
  SaveParameters,
} from 'projects/common/src/lib/components/person/person-detail-base.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormComponent } from 'projects/common/src/lib/components/form.component';
import { NotificationService } from 'projects/common/src/lib/services/notification.service';
import { PersonProfile } from 'projects/common/src/lib/models/person-profile';
import { PersonService } from 'projects/common/src/lib/services/api/person.service';
import { TenantSettings } from 'projects/common/src/lib/constants/jurisdiction/TenantSettings';
import { PersonDetailService } from 'projects/common/src/lib/services/person-detail.service';
import { getApiErrorMessage, getFormErrorMessage, touchFormGroupErrors } from 'projects/common/src/lib/utility/form-utils';
import { PersonContactInfo } from 'projects/common/src/lib/models/person-contact-info';
import { PersonDetailContactInformationComponent } from 'projects/common/src/lib/components/person/person-detail-contact-information/person-detail-contact-information.component';
import { PersonPersonalInfo } from 'projects/common/src/lib/models/person-personal-info';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss'],
})
export class PortalComponent extends FormComponent implements OnInit {
  availableApplication: ArchitectAvailableApplication;
  nameWithCredentials: string;
  workAddress$: Observable<Address>;
  homeAddress$: Observable<Address>;
  pointOfContacts$: Observable<PersonPointOfContact[]>;
  pointOfContactForList$: Observable<PointOfContactFor[]>;
  licenses$: Observable<ArchitectLicense[]>;
  applications: ArchitectApplication[];
  payments$: Observable<Transaction[]>;
  canManageFirm: boolean;
  personId: string;
  canHavePOC: boolean;
  applicationMutex: Mutex;
  sendMailToWork = false;
  personProfileForm: FormGroup;
  personProfile: PersonProfile;
  personalInfo: PersonPersonalInfo;
  contactInfoForm: FormGroup;
  contactInfo: PersonContactInfo;
  portalRequiredFields: Record<string, any>;

  pageLinks: LinkInfo[] = [];

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private architectApplicationService: ArchitectApplicationService,
    private authService: AuthService,
    public constants: JurisdictionConstants,
    private notificationService: NotificationService,
    private personDetailService: PersonDetailService,
    private personService: PersonService,
    protected formBuilder: FormBuilder,
    public settings: TenantSettings
  ) {
    super();
    this.personProfileForm = PersonProfile.GetFormGroup(formBuilder, true, this.settings);
    this.contactInfoForm = PersonProfile.GetContactInfoFormGroup(this.formBuilder);

    if (!this.settings.architectProfileSettings.allowUserEditingOfEmailAddress) this.contactInfoForm.controls.email.disable();
  }

  ngOnInit() {
    this.applicationMutex = new Mutex();
    this.route.params.subscribe(p => {
      this.personId = p.personId;
    });
    this.route.data.subscribe(data => {
      this.availableApplication = data.availableApplications[0];
      this.canManageFirm = !!this.authService.claims.managedFirms.length;
      this.canHavePOC = data.person.isArchitectOrApplicant;
      this.sendMailToWork = data.person.sendMailTo === 'Work';
    });

    this.unsubOnDestroy = this.personDetailService.person$.subscribe(person => {
      this.personProfile = new PersonProfile(person);
      this.personProfile.patch(this.personProfileForm);

      this.personalInfo = new PersonPersonalInfo({
        id: person.id,
        name: person.name,
        credentials: person.credentials,
        dateOfBirth: person.dateOfBirth,
        gender: person.gender,
        socialSecurityNumber: person.socialSecurityNumber,
        visaNumber: person.visaNumber,
        uscisNumber: person.uscisNumber,
        immigrationStatus: person.immigrationStatus,
        ncarbNumber: person.ncarbNumber,
        clarbNumber: person.clarbNumber,
      });
      this.personalInfo.patch(this.personProfileForm);

      this.contactInfo = new PersonContactInfo({
        email: person.email,
        primaryPhone: person.primaryPhone,
        secondaryPhone: person.secondaryPhone,
      });
      this.contactInfo.patch(this.contactInfoForm);
      this.portalRequiredFields = {
        dateOfBirth: person.dateOfBirth,
      };
      this.nameWithCredentials = person.nameWithCredentials;
      this.setSsnRequiredFields(person);

      this.disableSensitiveFields(person);
    });

    this.workAddress$ = this.route.data.pipe(map(d => d.person.workAddress));
    this.homeAddress$ = this.route.data.pipe(map(d => d.person.homeAddress));
    this.pointOfContacts$ = this.route.data.pipe(map(d => d.pointOfContacts));
    this.pointOfContactForList$ = this.route.data.pipe(map(d => d.pointOfContactForList));
    this.licenses$ = this.route.data.pipe(map(d => d.licenses));
    this.applications = this.route.snapshot.data.applications as ArchitectApplication[];
    for (let application of this.applications) {
      application.reviewRoute = ['/architect', this.personId, 'application', application.id, 'review'];
    }
    this.payments$ = this.route.data.pipe(map(d => d.payments));
    this.pageLinks = Array.from(this.getPageLinks(this.route.snapshot.data));
  }

  setSsnRequiredFields(person: PersonFull) {
    if (person.socialSecurityNumber) this.portalRequiredFields['socialSecurityNumber'] = person.socialSecurityNumber;

    if (person.uscisNumber) this.portalRequiredFields['uscisNumber'] = person.uscisNumber;

    if (person.visaNumber) this.portalRequiredFields['visaNumber'] = person.visaNumber;
  }

  disableSensitiveFields(person: PersonFull) {
    let infoForm = this.personProfileForm['controls'].info as FormGroup;

    if (person.socialSecurityNumber != null) infoForm.controls.socialSecurityNumber.disable();
    if (person.uscisNumber != null) infoForm.controls.uscisNumber.disable();
    if (person.visaNumber != null) infoForm.controls.visaNumber.disable();
    if (person.immigrationStatus != null) infoForm.controls.immigrationStatus.disable();
  }

  private *getPageLinks(data: any): Generator<LinkInfo> {
    yield { path: 'profile', label: 'Personal Information' };
    yield { path: 'contactDetails', label: 'Contact Information' };
    if (!this.settings.architectProfileSettings.hideHomeAddress) yield { path: 'homeAddress', label: 'Home Address' };
    yield { path: 'workDetails', label: this.settings.architectProfileSettings.workAddressCardTitle || 'Work Details' };
    if (!this.settings.architectProfileSettings.hideHomeAddress) yield { path: 'preferences', label: 'Preferences' };
    if (this.settings.hasArchPocs) yield { path: 'pointOfContact', label: 'Point of Contact' };
    if (data.licenses.length) yield { path: 'license', label: 'License Information' };
    if (data.applications.length) yield { path: 'applications', label: 'Applications' };
    if (data.payments.length) yield { path: 'payments', label: 'Payments' };
    if (this.settings.hasProfessionalReferences) yield { path: 'references', label: 'References' };
    if (this.settings.enableEasyTesting) yield { path: 'testing', label: 'Testing Setup' };
  }

  async gotoApplication() {
    if (!this.applicationMutex.tryAcquire()) {
      return false;
    }

    try {
      const application = await this.architectApplicationService.getOrCreate(
        this.personId,
        this.availableApplication.applicationType,
        this.availableApplication.profession
      );
      await this.router.navigate(['/architect', this.personId, 'application', application.id]);
      this.applicationMutex.release();
    } catch (error) {
      console.error(error);
      this.applicationMutex.release();
    }
  }

  reload() {
    this.router.navigate(['.'], { relativeTo: this.route });
  }

  async onPersonalInfoSave(editingComponent: PersonDetailBaseComponent) {
    const form = this.personProfileForm;
    if (!this.validateForm(form)) return;

    this.personalInfo.acceptPatch(form);
    this.notificationService.notifyWait('Saving');

    try {
      await this.personService.updatePersonPersonalInfo(this.personalInfo.id, this.personalInfo, true);
      editingComponent.isEditing = false;
      this.notificationService.notifySuccess(editingComponent.successfulSaveMessage);
    } catch (e) {
      const errorMessage = getApiErrorMessage(e, editingComponent.errorSaveMessage);
      this.notificationService.notifyFail(errorMessage);
    }
  }

  async onContactInfoSave(editingComponent: PersonDetailContactInformationComponent) {
    const form = editingComponent.contactInfoForm;
    if (!this.validateForm(form)) return;
    this.contactInfo.acceptPatch(form);
    this.notificationService.notifyWait('Saving');

    try {
      await this.personService.updateContactInfo(this.personProfile.id, this.contactInfo);
      editingComponent.isEditing = false;
      this.notificationService.notifySuccess(editingComponent.successfulSaveMessage);
    } catch (e) {
      const errorMessage = getApiErrorMessage(e, editingComponent.errorSaveMessage);
      this.notificationService.notifyFail(errorMessage);
    }
  }

  private async saveFirmName(firmName: string, errorSaveMessage: string, successfulSaveMessage?: string): Promise<boolean> {
    try {
      const updatedPerson = await this.personService.updatePersonFirmName(this.personProfile.id, firmName);
      if (successfulSaveMessage) this.notificationService.notifySuccess(successfulSaveMessage);
      this.personDetailService.next(updatedPerson);
      updatedPerson.patch(this.personProfileForm);
      return true;
    } catch (e) {
      const errorMessage = getApiErrorMessage(e, errorSaveMessage);
      this.notificationService.notifyFail(errorMessage);
    }
    return false; //save failed
  }

  async onSaveAddressPreference(editingComponent: PersonDetailBaseComponent) {
    try {
      const sendMailTo = (<FormGroup>this.personProfileForm.controls.addresses).controls.sendMailTo.value;
      const updatedPerson = await this.personService.updatePersonAddressPreference(this.personalInfo.id, sendMailTo);
      this.personDetailService.next(updatedPerson);
      updatedPerson.patch(this.personProfileForm);
      editingComponent.isEditing = false;
      this.notificationService.notifySuccess(editingComponent.successfulSaveMessage);
    } catch (e) {
      const errorMessage = getApiErrorMessage(e, editingComponent.errorSaveMessage);
      this.notificationService.notifyFail(errorMessage);
    }
  }

  async onSectionSave({ form, defaultErrorMessage, successMessage, callApi, profileUpdate }: SaveParameters) {
    if (form.invalid) {
      touchFormGroupErrors(form);
      const errorMessage = getFormErrorMessage(form, defaultErrorMessage);
      this.notificationService.notifyFormIssue(errorMessage);
      return;
    }

    this.notificationService.notifyWait('Saving');

    try {
      if (profileUpdate && profileUpdate.firmName !== this.personProfile.firmName) {
        //section update effected something on profile (only firmName at this time)
        const saveSuccessful = await this.saveFirmName(profileUpdate.firmName, defaultErrorMessage);
        if (!saveSuccessful) {
          return;
        }
      }
      await callApi();
      this.notificationService.notifySuccess(successMessage);
    } catch (e) {
      const errorMessage = getApiErrorMessage(e, defaultErrorMessage);
      this.notificationService.notifyFail(errorMessage);
    }
  }

  getFormValidationErrorMessage(defaultMsg: string): string {
    let errorList = new Array<string>();

    Object.keys(this.personProfileForm.controls).forEach(groupKey => {
      let group = this.personProfileForm.controls[groupKey] as FormGroup;

      if (group.errors) {
        Object.keys(group.errors).forEach(errorKey => {
          let error = group.errors[errorKey];

          if (error.message) errorList.push(error.message);
        });
      }
    });

    return errorList.length > 0 ? errorList.join('\n') : defaultMsg;
  }

  validateForm(form: FormGroup): boolean {
    if (form.invalid) {
      touchFormGroupErrors(form);
      this.notificationService.notifyFormIssue(getFormErrorMessage(form, 'Please check the form.  There were some issues.'));
      return false;
    }
    return true;
  }
}
