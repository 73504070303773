import { JurisdictionConstants } from './jurisdiction-constants';
import { Profession } from './profession';

export const NEBOGConstants: JurisdictionConstants = {
  legal: '',
  acronym: 'NEBOG',
  workAddressSuffix: '(Public)',
  homeAddressSuffix: '(Private)',
  commonLinks: {
    publicSite: 'https://nebog.nebraska.gov/',
    publicUrl: 'nebog.nebraska.gov',
    registrationInfo: 'https://nebog.nebraska.gov/',
    rules: 'https://nebog.nebraska.gov/geologists-regulation-act',
    centuryCode: null,
    lapels: '',
    architectLookup: '',
    firmLookup: '',
  },
  manualProfessions: [Profession.Geologist],
  architectTabName: 'Geologists',
  historyLogEventTitleArchitectReplacement: 'Geologist',
  ceRule: {
    section: '§1315.D',
    exemption: '§1315.B',
  },
  professionalReferences: {
    text: `If you need to submit additional references to the board, <a href="https://nebog.nebraska.gov/sites/default/files/doc/Reference%20Form_180807.pdf">download the reference form</a>, send it to another desired reference, and have them send it directly to the board. Applicants that cannot furnish the required number of references for an application, will need to email a detailed letter to the board at nebog.board@nebraska.gov requesting an exemption from this requirement`,
    dataPoint: 'References Received',
    cardTitle: 'References',
  },
  timezoneAbbreviation: 'CT',
  checkAddress: {
    address1: 'Nebraska Board of Geologists',
    address2: 'PO Box 94844',
    cityStateZip: 'Lincoln, NE  68509-4844',
  },
  applicationStepLabels: {
    continuingEducation: 'Professional Development Hours',
    confirmation: 'Affidavit',
  },
  applicationStepContent: {
    disclosureQuestions: {
      subtitle:
        'Please answer the following questions truthfully. If yes, please provide an explanation and supporting documentation (i.e. court documents, disciplinary action agreement).',
    },
    militaryInfo: {
      question: 'Are you active military or the spouse of an active military member?',
    },
    certification: {
      headerSubtext: 'Attestation',
      headerSubtextContent: 'Using the checkboxes below, please certify the information on your application.',
      attestationsLabel: 'I certify that:',
    },
    payment: {
      headerSubtextContent: `Please select one of the following payment options to submit your application. In the event that
        your check is returned unpaid for insufficient or uncollected funds, we may ask you to resubmit payment using a
        different method. Please be aware that some banks will not allow an electronic debit by a third party, if you are
        unsure if your bank will allow this transaction you may want to contact them before proceeding.`,
      headerSubtextContent_architectRenewal: `Upon receipt of this application and the appropriate fee, the individual described herein will be authorized to practice geology in the state of Nebraska, unless the application is disapproved. This authority will expire on {{ExpirationDate}}. Failure to renew in a timely manner will result in fines and penalties.`,
      byClickingSubmitAch:
        'By clicking ‘Submit Application’ you are agreeing to pay the total amount stated and submitting your official application for licensure. All fees are non-refundable.',
      byClickingSubmitNotAch:
        'By clicking ‘Submit Application’ you are agreeing to pay the total amount stated and submitting your official application for licensure. All fees are non-refundable.',
    },
    lawfulPresence: {
      headerSubtextContent: `For the purpose of complying with <a href="https://nebraskalegislature.gov/laws/statutes.php?statute=4-108">Neb. Rev. Stat. §§ 4-108 through 4-114</a>, I attest as follows`,
      nonCitizenUploadText: 'Please provide documentation for your selection.',
      hideAcceptableDocuments: true,
    },
    submitted: {
      headerSubtextContent: `Thank you for submitting your license application. Please take note of your confirmation number.
        You should receive an email confirming your application submission within the next 24 hours. The board may follow up
        with you regarding any outstanding details. Your patience and cooperation are much appreciated.`,
    },
    businessInformationContent: {
      supervisingProfessionalSectionHeader: 'Individual(s) in Responsible Charge',
      supervisingProfessionalDescription: `<p>Please designate an individual in responsible charge to serve as a contact for the organization's projects in Nebraska. The named individual does not need to work on every project, but should be familiar with professional activities of the organization in order to answer any questions the Board may have regarding a project.</p>
      <p>A geologist who renders occasional, part-time or consulting services to or for the organization may not be designated as being in responsible charge for the professional activities of the organization.</p>`,
      supervisingProfessionalArchSelectLabel: 'Individual in Responsible Charge',
    },
  },
};
