import { FormBuilder, FormGroup } from '@angular/forms';
import { Phone } from './phone';
import { WorkHistoryBase } from './work-history-base';
import { FirmLicense } from './firm-license';
import { Firm } from './firm';
import { isMoment } from 'moment';

// TODO refactor WorkHistoryForArchApp out of arch app review
// and consolilate to just WorkHistory
export class WorkHistory extends WorkHistoryBase<WorkHistory> {
  startDate?: Date;
  endDate?: Date;

  acceptPatch(formGroup: FormGroup) {
    var startDate = formGroup.controls.startDate.value;
    if (startDate && isMoment(startDate)) {
      formGroup.controls.startDate.setValue(startDate.format());
    }
    var endDate = formGroup.controls.endDate.value;
    if (endDate && isMoment(endDate)) {
      formGroup.controls.endDate.setValue(endDate.format());
    }
    this.update(formGroup.value);
  }

  patch(formGroup: FormGroup, firmLicense: FirmLicense) {
    formGroup.patchValue(this);
    if (this.phone) {
      this.phone.patch(formGroup.controls.phone as FormGroup);
    }
    formGroup.controls.firmLicense.setValue(firmLicense);
    formGroup.controls.currentlyWorkHere.setValue(!this.endDate);
    if (!this.endDate && !!this.startDate) {
      formGroup.controls.endDate.disable();
    }
  }

  asFirmLicense(): FirmLicense {
    let firm = new Firm(this.firm);
    firm.nameFull = this.firm.name;
    let firmLicense = new FirmLicense({
      number: this.firm.licenseNumber || 'Unlicensed',
      status: this.firm.licenseStatus || 'Unlicensed',
      firm: firm,
    });
    return firmLicense;
  }

  static GetFormGroup(formBuilder: FormBuilder): FormGroup {
    return formBuilder.group({
      id: '',
      email: '',
      title: '',
      phone: Phone.GetFormGroup(formBuilder),
      firmLicense: '',
      startDate: '',
      endDate: '',
      currentlyWorkHere: '',
    });
  }
}
