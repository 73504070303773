<lib-side-nav-container>
  <aside>
    <div #sticky></div>
    <div stickyThing [spacer]="sticky">
      <nav>
        <mat-nav-list class="side-links" role="navigation" snScrollSpy id="widgetLinks">
          <a
            mat-list-item
            [href]="'#' + link.path"
            [toFragment]="link.path"
            *ngFor="let link of pageLinks"
            snScrollSpyItem
            for="widgetLinks"
          >
            {{ link.label }}
          </a>
        </mat-nav-list>
      </nav>
      <div>
        <app-portal-actions [canHavePOC]="canHavePOC"></app-portal-actions>
      </div>
    </div>
  </aside>

  <ng-container *ngIf="licenses$ | async as licenses">
    <lib-license-status-pill *ngFor="let license of licenses" class="float-right" [license]="license"></lib-license-status-pill>
  </ng-container>
  <h1>{{ nameWithCredentials }}</h1>

  <lib-call-to-action *ngIf="availableApplication">
    <h2>{{ availableApplication.callToAction.header }}</h2>
    <p>{{ availableApplication.callToAction.body }}</p>
    <button *ngIf="!availableApplication.callToAction.actionUrl" mat-button color="primary" (click)="gotoApplication()">
      {{ availableApplication.callToAction.action }}
    </button>
    <a
      *ngIf="availableApplication.callToAction.actionUrl"
      mat-button
      color="primary"
      [href]="availableApplication.callToAction.actionUrl"
      target="_blank"
    >
      {{ availableApplication.callToAction.action }}
    </a>
  </lib-call-to-action>

  <sn-scroll-spy-section id="profile" for="widgetLinks"></sn-scroll-spy-section>
  <app-person-detail-personal-info
    [personProfileForm]="personProfileForm"
    (onSave)="onPersonalInfoSave($event)"
    [requiredFields]="portalRequiredFields"
  ></app-person-detail-personal-info>

  <sn-scroll-spy-section id="contactDetails" for="widgetLinks"></sn-scroll-spy-section>
  <app-person-detail-contact-information
    [contactInfoForm]="contactInfoForm"
    (onSave)="onContactInfoSave($event)"
  ></app-person-detail-contact-information>

  <sn-scroll-spy-section id="homeAddress" for="widgetLinks"></sn-scroll-spy-section>
  <app-person-home-address (onSectionSave)="onSectionSave($event)" *ngIf="!settings.architectProfileSettings.hideHomeAddress">
  </app-person-home-address>

  <sn-scroll-spy-section id="workDetails" for="widgetLinks"></sn-scroll-spy-section>
  <app-person-work-details (onSectionSave)="onSectionSave($event)"> </app-person-work-details>

  <sn-scroll-spy-section
    id="preferences"
    for="widgetLinks"
    *ngIf="!settings.architectProfileSettings.hideHomeAddress"
  ></sn-scroll-spy-section>
  <app-person-preferences
    [personProfileForm]="personProfileForm"
    (onSave)="onSaveAddressPreference($event)"
    *ngIf="!settings.architectProfileSettings.hideHomeAddress"
  >
  </app-person-preferences>

  <ng-template [ngIf]="settings.hasArchPocs">
    <sn-scroll-spy-section id="pointOfContact" for="widgetLinks"></sn-scroll-spy-section>
    <lib-point-of-contact-card
      [pointOfContacts$]="pointOfContacts$"
      [pointOfContactForList$]="pointOfContactForList$"
      [canHavePOC]="canHavePOC"
    >
    </lib-point-of-contact-card>
  </ng-template>

  <sn-scroll-spy-section id="license" for="widgetLinks"></sn-scroll-spy-section>
  <ng-container *ngIf="licenses$ | async as licenses">
    <app-portal-architect-registration *ngFor="let license of licenses" [license]="license"></app-portal-architect-registration>
  </ng-container>

  <sn-scroll-spy-section id="applications" for="widgetLinks"></sn-scroll-spy-section>
  <app-application-list
    title="Applications"
    [list]="applications"
    [reviewLatest]="true"
    [hideOnEmpty]="true"
  ></app-application-list>

  <sn-scroll-spy-section id="payments" for="widgetLinks"></sn-scroll-spy-section>
  <lib-titled-card *ngIf="(payments$ | async).length">
    <h2>Payments</h2>
    <app-payment-list [list$]="payments$"> </app-payment-list>
  </lib-titled-card>

  <sn-scroll-spy-section id="references" for="widgetLinks"></sn-scroll-spy-section>
  <app-references-detail *ngIf="settings.hasProfessionalReferences"></app-references-detail>

  <sn-scroll-spy-section id="testing" for="widgetLinks"></sn-scroll-spy-section>
  <app-setup-application (change)="reload()"></app-setup-application>

  <app-setup-manage-firm (change)="reload()" *ngIf="settings.doesLicenseFirms"></app-setup-manage-firm>
</lib-side-nav-container>
